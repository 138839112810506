import { bindable, inject } from "aurelia-framework";
import $ from "jquery";

import "./dropdown-menu.less";

$(window).on("mousedown", () => {
    // Close all actions menus when click is not in menu
    $(".dropdown-menu-items").hide();
});

$(window).on("blur", () => {
    // Close all actions menus when focus is lost
    $(".dropdown-menu-items").hide();
});

@inject(Element)
export class DropdownMenu {
    @bindable actions;
    @bindable label;
    @bindable title;
    @bindable context;
    @bindable icon = "actions-menu";
    @bindable menuBtnClass = "btn btn-default btn-sm";
    @bindable maxHeight = 400;

    constructor(element) {
        this.element = element;
    }

    attached() {
        this.maxHeightChanged();
    }

    maxHeightChanged() {
        if (this.element) {
            $(this.element)
                .find(".dropdown-menu-items")
                .css("max-height", this.maxHeight);
        }
    }

    toggleActionsMenu(event) {
        const targetItem = $(event.target)
            .parent()
            .find(".dropdown-menu-items");
        const tableActionItems = $(event.target)
            .parent()
            .parent()
            .parent()
            .parent()
            .find(".table-action-items");
        const rect = event.target.getBoundingClientRect();
        const buttonBottom = rect.top + event.target.offsetHeight;
        const width = targetItem.outerWidth() + 5;

        let left = 0;
        let topAdj = 0;
        if (tableActionItems.length > 0) {
            left = tableActionItems.offset().left - width;
            topAdj = rect.height;
        } else {
            left = rect.left - targetItem.outerWidth();
        }

        if (
            document.documentElement.clientHeight <
            this.maxHeight + buttonBottom
        ) {
            targetItem.css({
                top: undefined,
                bottom: document.documentElement.clientHeight - 38 - rect.top + "px",
            });
        } else {
            targetItem.css({
                top: buttonBottom - topAdj + "px",
                bottom: undefined,
            });
        }

        if (targetItem.is(":visible")) {
            $(targetItem).slideUp(200);
        } else {
            const items = $(targetItem);
            items.filter((index) => items[index] !== targetItem[0]).hide();
            targetItem.slideDown(200);
            targetItem.css("left", left + "px");
        }

        event.stopPropagation();
    }
}
