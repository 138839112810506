import React, { useState, useEffect } from "react";
import { GoogleApiWrapper, Map, Marker, InfoWindow } from "google-maps-react";
import { Button } from "rsuite";
import "./sio-google-map.less";

const InfoWindowEx = (props) => {
    const infoWindowRef = React.createRef();
    const contentElement = document.createElement(`div`);
    useEffect(() => {
        ReactDOM.render(React.Children.only(props.children), contentElement);
        infoWindowRef.current.infowindow.setContent(contentElement);
    }, [props.children]);
    return (
        <InfoWindow
            className="sio-google-map__inforWindow"
            ref={infoWindowRef}
            {...props}
            options={{ maxHeight: 200 }}
        />
    );
};

const removeEmptyMarkers = (markers) => {
    if (!markers || !Array.isArray(markers)) {
        return null;
    }

    return markers.filter((marker) => {
        return !(
            typeof marker.position == "undefined" ||
            typeof marker.position.lat == "undefined" ||
            typeof marker.position.lng == "undefined"
        );
    });
};

const renderMarker = (marker, key) => {
    let onDragend = null;

    if ("onDragend" in marker) {
        onDragend = (e, x) => marker.onDragend(e, x);
    }

    if (!marker) {
        return null;
    }
    let props = {
        title: "title" in marker ? marker.title : "",
        name: "title" in marker ? marker.title : "",
        draggable: "draggable" in marker ? marker.draggable : false,
        onDragend: onDragend,
        onClick: "onClick" in marker ? marker.onClick : null,
        position: "position" in marker ? marker.position : null,
    };

    return <Marker key={key} {...props}></Marker>;
};

const FrankfurtAmMainBoundaries = [
    {
        //top left boundary point
        lat: 50.142002,
        lng: 8.6073363,
    },
    {
        //bottom right boundary point
        lat: 50.079344,
        lng: 8.779646,
    },
];

const extendWithFrankfurt = (bounds) => {
    FrankfurtAmMainBoundaries.forEach((coords) => bounds.extend(coords));
};

const SIOGoogleMapComponent = ({
    google,
    markers = [],
    polylines = [],
    directions = [],
    bounds = [],
    zoom = undefined,
    height = "500px",
    position = "relative",
    hasInfoView = false,
    onReady,
}) => {
    const [activeMarker, setActiveMarker] = useState({});
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState(null);

    const onMarkerClick = (props, m) => {
        setActiveMarker(m);
        setShowInfoWindow(true);
    };

    let getBounds = () => {
        let _bounds = new window.google.maps.LatLngBounds();

        if (bounds && bounds.length > 0) {
            extendWithFrankfurt(_bounds);
        } else if (markers && markers.length > 0) {
            markers.forEach((marker) => {
                if (
                    !marker ||
                    !"position" in marker ||
                    typeof marker.position == "undefined" ||
                    typeof marker.position.lat == "undefined" ||
                    typeof marker.position.lng == "undefined"
                ) {
                    return;
                }
                _bounds.extend({
                    lat: marker.position.lat + 0.001,
                    lng: marker.position.lng + 0.001,
                });

                _bounds.extend({
                    lat: marker.position.lat - 0.001,
                    lng: marker.position.lng - 0.001,
                });
            });
        } else {
            extendWithFrankfurt(_bounds);
        }

        directions?.forEach((d) => _bounds.union(d.routes[0].bounds));

        return _bounds;
    };

    let zoomFactor = (minLngWest, maxLngEast) => {
        let globe_width = 256;
        let angel = maxLngEast - minLngWest;
        if (angel < 0) {
            angel += 360;
        }
        return (
            Math.round(Math.log((500 * 360) / angel / globe_width) / Math.LN2) -
            2
        );
    };

    const renderMarkers = (markers) => {
        if (!markers || !Array.isArray(markers)) {
            return null;
        }

        if (hasInfoView) {
            return markers.map((mapMarker, index) => (
                <Marker
                    key={index}
                    onClick={(props, m) => {
                        onMarkerClick(props, m);
                        setSelectedMarker(mapMarker);
                    }}
                    position={{
                        lat: mapMarker.position.lat,
                        lng: mapMarker.position.lng,
                    }}
                    component={mapMarker.component}
                    name={mapMarker.title}
                />
            ));
        }

        return markers.map((marker, key) => {
            return renderMarker(marker, key);
        });
    };

    markers = removeEmptyMarkers(markers);

    let mapBounds = getBounds();

    return (
        <Map
            mapType={"roadmap"}
            google={google}
            containerStyle={{
                width: "100%",
                height: height,
                position: position,
            }}
            bounds={mapBounds}
            initialCenter={{
                lat: mapBounds.getCenter().lat(),
                lng: mapBounds.getCenter().lng(),
            }}
            onReady={onReady}
            zoom={zoom}
        >
            {renderMarkers(markers)}
            {hasInfoView && (
                <InfoWindowEx marker={activeMarker} visible={showInfoWindow}>
                    <div className="sio-google-map__infoWindowWrapper">
                        {selectedMarker?.component}
                        <Button
                            size="xs"
                            appearance="ghost"
                            color="orange"
                            onClick={() => {
                                selectedMarker.onMarkerSelect();
                            }}
                        >
                            Öffnen
                        </Button>
                    </div>
                </InfoWindowEx>
            )}
        </Map>
    );
};

export const SIOGoogleMap = GoogleApiWrapper((props) => ({
    apiKey: props.apiKey,
    language: props.language,
}))(SIOGoogleMapComponent);
